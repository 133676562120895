var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "navbar navbar-expand-lg navbar-dark",
      attrs: { "data-cy": "navbar", toggleable: "md", type: "dark" },
    },
    [
      _c(
        "b-navbar-brand",
        { staticClass: "logo", attrs: { "b-link": "", to: "/" } },
        [
          _vm.companyInfo == null ? _c("span") : _vm._e(),
          _vm._v(" "),
          _vm.company.icon == null
            ? _c("span", { staticClass: "logo-img" })
            : _vm._e(),
          _vm._v(" "),
          _vm.company.icon
            ? _c("img", {
                staticClass: "logo-img",
                attrs: {
                  src: _vm.company.icon
                    ? _vm.company.icon
                    : "../../../content/images/logo-jhipster.png",
                  alt: "",
                },
              })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-navbar-toggle",
        {
          staticClass: "jh-navbar-toggler d-lg-none",
          attrs: {
            right: "",
            href: "javascript:void(0);",
            "data-toggle": "collapse",
            target: "header-tabs",
            "aria-expanded": "false",
            "aria-label": "Toggle navigation",
          },
        },
        [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { "is-nav": "", id: "header-tabs" } },
        [
          _c(
            "b-navbar-nav",
            [
              !_vm.authenticated
                ? _c(
                    "b-nav-item",
                    {
                      staticStyle: { visibility: "hidden" },
                      attrs: { exact: "" },
                    },
                    [
                      _c(
                        "span",
                        [
                          _c("font-awesome-icon", { attrs: { icon: "home" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(_vm.$t("global.menu.home")),
                              },
                            },
                            [_vm._v("Home")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated &&
              (_vm.hasAnyAuthority("ROLE_COMPANY_ADMIN") ||
                _vm.hasAnyAuthority("ROLE_USER"))
                ? _c(
                    "b-nav-item",
                    {
                      class: {
                        actived: _vm.getActive("jijiwaiwai"),
                      },
                      attrs: { to: { name: "Customer" } },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "users" } }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("global.menu.entities.customer")
                            ),
                          },
                        },
                        [_vm._v("Customer")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
                ? _c(
                    "b-nav-item",
                    {
                      class: {
                        active: _vm.getActive("Company"),
                      },
                      attrs: { to: { name: "Company" } },
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "bi bi-building",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "16",
                            height: "16",
                            fill: "currentColor",
                            viewBox: "0 0 16 16",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              d: "M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("global.menu.entities.company")
                            ),
                          },
                        },
                        [_vm._v("Company")]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated &&
              (_vm.hasAnyAuthority("ROLE_COMPANY_ADMIN") ||
                _vm.hasAnyAuthority("ROLE_ADMIN"))
                ? _c(
                    "b-nav-item",
                    {
                      class: {
                        active: _vm.getActive("JhiUser"),
                      },
                      attrs: { to: { name: "JhiUser" } },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "users" } }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("global.menu.admin.userManagement")
                            ),
                          },
                        },
                        [_vm._v("User management")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated && _vm.hasAnyAuthority("ROLE_COMPANY_ADMIN")
                ? _c(
                    "b-nav-item",
                    {
                      class: {
                        active: _vm.getActive("OperationLog"),
                      },
                      attrs: { to: { name: "OperationLog" } },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "list" } }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("global.menu.entities.operationLog")
                            ),
                          },
                        },
                        [_vm._v("Operation Log")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated && _vm.hasAnyAuthority("ROLE_COMPANY_ADMIN")
                ? _c(
                    "b-nav-item",
                    {
                      class: {
                        active: _vm.getActive("SystemSetting"),
                      },
                      attrs: { to: { name: "SystemSetting" } },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "cogs" } }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("global.menu.entities.systemSetting")
                            ),
                          },
                        },
                        [_vm._v("System Setting")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.languages && Object.keys(_vm.languages).length > 1
                ? _c(
                    "b-nav-item-dropdown",
                    { attrs: { id: "languagesnavBarDropdown", right: "" } },
                    [
                      _c(
                        "span",
                        {
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "flag" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.language")
                                ),
                              },
                            },
                            [_vm._v("Language")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.languages, function (value, key) {
                        return _c(
                          "b-dropdown-item",
                          {
                            key: "lang-" + key,
                            class: { active: _vm.isActiveLanguage(key) },
                            on: {
                              click: function ($event) {
                                return _vm.changeLanguage(key)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(value.name) + "\n        "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "pointer",
                  class: { "router-link-active": _vm.subIsActive("/account") },
                  attrs: {
                    right: "",
                    href: "javascript:void(0);",
                    id: "account-menu",
                    "active-class": "active",
                    "data-cy": "accountMenu",
                  },
                },
                [
                  this.$store.getters.account
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "nav-item b-nav-dropdown dropdown pointer",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _vm.hasAnyAuthority("ROLE_COMPANY_ADMIN") ||
                          _vm.hasAnyAuthority("ROLE_ADMIN")
                            ? _c(
                                "svg",
                                {
                                  staticClass: "bi bi-person-lines-fill",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "16",
                                    height: "16",
                                    fill: "#333",
                                    viewBox: "0 0 16 16",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", { staticClass: "no-bold" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.$store.getters.account
                                    ? this.$store.getters.account.firstName
                                    : ""
                                ) +
                                " "
                            ),
                          ]),
                          _vm._v("  \n        "),
                        ]
                      )
                    : _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            staticStyle: { color: "#333" },
                            attrs: { icon: "user" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.main")
                                ),
                              },
                            },
                            [_vm._v(" Account ")]
                          ),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "settings",
                            to: { name: "Settings" },
                            tag: "b-dropdown-item",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "wrench" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.settings")
                                ),
                              },
                            },
                            [_vm._v("Settings")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "passwordItem",
                            to: { name: "ChangePassword" },
                            tag: "b-dropdown-item",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "lock" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.password")
                                ),
                              },
                            },
                            [_vm._v("Password")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "logout",
                            id: "logout",
                            "active-class": "active",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.logout()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "sign-out-alt" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.logout")
                                ),
                              },
                            },
                            [_vm._v("Sign out")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "login",
                            id: "login",
                            "active-class": "active",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openLogin()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "sign-in-alt" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.login")
                                ),
                              },
                            },
                            [_vm._v("Sign in")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }