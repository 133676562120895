import { Component, Inject, Vue } from 'vue-property-decorator';
import LoginService from '@/account/login.service';
import AccountService from '@/account/account.service';
import TranslationService from '@/locale/translation.service';

import EntitiesMenu from '@/entities/entities-menu.vue';
import { ICompany } from '@/shared/model/company.model';
import axios from 'axios';
import { throws } from 'assert/strict';

@Component({
  components: {
    'entities-menu': EntitiesMenu,
  },
})
export default class JhiNavbar extends Vue {
  @Inject('loginService')
  private loginService: () => LoginService;
  @Inject('translationService') private translationService: () => TranslationService;

  @Inject('accountService') private accountService: () => AccountService;
  public version = 'v' + VERSION;
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;
  private hasAnyAuthorityValues = {};
  public company: ICompany = {};

  created() {
    this.translationService().refreshTranslation(this.currentLanguage);
  }

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }
  public getActive(name) {
    console.log(name, this.$route.name, name == this.$route.name);
    return name == this.$route.name;
  }
  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public logout(): Promise<any> {
    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('baseUrl');
    sessionStorage.removeItem('account_data');
    this.$store.commit('logout');
    if (this.$route.name !== 'Home') {
      return this.$router.push({ name: 'Home' });
    }
    this.$router.go(0);
    return Promise.resolve(this.$router.currentRoute);
  }

  public openLogin(): void {
    this.loginService().openLogin((<any>this).$root);
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        this.hasAnyAuthorityValues[authorities] = value;
      });
    return this.hasAnyAuthorityValues[authorities] ?? false;
  }

  public get openAPIEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('api-docs') > -1;
  }

  public get inProduction(): boolean {
    return this.$store.getters.activeProfiles.indexOf('prod') > -1;
  }

  // 获取公司名称
  public get companyName(): string {
    let name = this.$store.getters.account?.companyName ?? 'CandyCrm';
    document.getElementById('global_title').innerText = name;
    return name;
  }

  // 获取公司信息
  public get companyInfo(): any {
    let info = this.$store.getters.account?.companyInfo ?? null;
    if (info != null) {
      this.company = info;
      document.getElementById('global_icon').setAttribute('href', info.icon);
      document.getElementById('global_title').innerText = info.name;
    } else {
      this.getCompanyInfo();
    }
    return info;
  }

  public getCompanyInfo() {
    return this.find()
      .then(res => {
        this.company = res;
        document.getElementById('global_title').innerText = res.name;
        document.getElementById('global_icon').setAttribute('href', res.icon);
        return res;
      })
      .catch(error => {
        console.log(error);
      });
  }

  public find(): Promise<ICompany> {
    return new Promise<ICompany>((resolve, reject) => {
      let baseUrl = location.href;
      let a = baseUrl.split('//');
      let b = a.splice(1, 1).toString();
      baseUrl = b.substring(b.indexOf('/') + 1);
      if (baseUrl && !sessionStorage.getItem('jhi-authenticationToken')) {
        axios
          .get('/api/company-info?url=' + baseUrl)
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      }
    });
  }
}
