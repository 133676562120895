import { Component, Inject, Vue } from 'vue-property-decorator';
import { Authority } from '@/shared/security/authority';
import AccountService from '@/account/account.service';

@Component
export default class EntitiesMenu extends Vue {
  // 用于权限控制
  @Inject('accountService') private accountService: () => AccountService;
  private hasAnyAuthorityValues = {};

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        this.hasAnyAuthorityValues[authorities] = value;
      });
    return this.hasAnyAuthorityValues[authorities] ?? false;
  }

  // 通过这个方法让外部能调用此方法，从而使用 Authority 这个枚举中的变量
  public auth(){
    return Authority;
  }
}
