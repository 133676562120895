var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasAnyAuthority(_vm.auth().ADMIN)
        ? _c(
            "b-dropdown-item",
            { attrs: { to: { name: "Company" } } },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(_vm.$t("global.menu.entities.company")),
                  },
                },
                [_vm._v("Company")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority(_vm.auth().COMPANY_ADMIN) ||
      _vm.hasAnyAuthority(_vm.auth().USER)
        ? _c(
            "b-dropdown-item",
            { attrs: { to: { name: "Customer" } } },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.customer")
                    ),
                  },
                },
                [_vm._v("Customer")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority(_vm.auth().COMPANY_ADMIN)
        ? _c(
            "b-dropdown-item",
            { attrs: { to: { name: "OperationLog" } } },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.operationLog")
                    ),
                  },
                },
                [_vm._v("Operation Log")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority(_vm.auth().COMPANY_ADMIN)
        ? _c(
            "b-dropdown-item",
            { attrs: { to: { name: "SystemSetting" } } },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.systemSetting")
                    ),
                  },
                },
                [_vm._v("System Setting")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }