import axios from 'axios';
import Component from 'vue-class-component';
import { Vue, Inject } from 'vue-property-decorator';
import AccountService from '@/account/account.service';
@Component({
  watch: {
    $route() {
      this.$root.$emit('bv::hide::modal', 'login-page');
    },
  },
})
export default class LoginForm extends Vue {
  @Inject('accountService')
  private accountService: () => AccountService;
  public authenticationError = null;
  public login = null;
  public password = null;
  public rememberMe: boolean = null;

  public doLogin(): void {
    // 获取域名后面的公司代号，拼接到路由上
    let baseUrl = location.href;
    let a = baseUrl.split('//');
    let b = a.splice(1, 1).toString();
    baseUrl = b.substring(b.indexOf('/'));
    sessionStorage.setItem('baseUrl', baseUrl);

    const data = { username: this.login, password: this.password, rememberMe: this.rememberMe };
    axios
      .post('api/authenticate', data)
      .then(result => {
        const bearerToken = result.headers.authorization;
        if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
          const jwt = bearerToken.slice(7, bearerToken.length);
          if (this.rememberMe) {
            localStorage.setItem('jhi-authenticationToken', jwt);
            sessionStorage.removeItem('jhi-authenticationToken');
          } else {
            sessionStorage.setItem('jhi-authenticationToken', jwt);
            localStorage.removeItem('jhi-authenticationToken');
          }
        }
        this.authenticationError = false;
        this.$root.$emit('bv::hide::modal', 'login-page');
        this.accountService().retrieveAccount();

        this.toHome();

        // 登录之后刷新一次页面
        // this.$router.push(sessionStorage.getItem('baseUrl'));
      })
      .catch(() => {
        this.authenticationError = true;
      });
  }

  // 自定义，根据角色跳转不同的页面
  public toHome(): void {
    new Promise(resolve => {
      axios
        .get<any>('api/account')
        .then(response => {
          const account = response.data;
          sessionStorage.setItem('account_data', JSON.stringify(account));
          if (account) {
            console.log('account');
            if (account.companyInfo) {
              console.log('comapnyinfo');
              if (!sessionStorage.getItem('baseUrl') || '/' + account.companyInfo.url != sessionStorage.getItem('baseUrl')) {
                sessionStorage.setItem('baseUrl', '/' + account.companyInfo.url);
                if (account.authorities.indexOf('ROLE_ADMIN') >= 0) {
                  window.location.href = '/company';
                } else {
                  window.location.href = '/' + account.companyInfo.url + '/customer';
                }
              } else {
                window.location.href = '/' + account.companyInfo.url + '/customer';
              }
            } else {
              sessionStorage.setItem('baseUrl', '/');
              if (account.authorities.indexOf('ROLE_ADMIN') >= 0) {
                window.location.href = '/company';
              } else {
                this.$router.push({ name: 'Customer' });
              }
            }
          }
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }
}
