import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Company = () => import('@/entities/company/company.vue');
// prettier-ignore
const CompanyUpdate = () => import('@/entities/company/company-update.vue');
// prettier-ignore
const CompanyDetails = () => import('@/entities/company/company-details.vue');
// prettier-ignore
const Customer = () => import('@/entities/customer/customer.vue');
// prettier-ignore
const CustomerUpdate = () => import('@/entities/customer/customer-update.vue');
// prettier-ignore
const CustomerDetails = () => import('@/entities/customer/customer-details.vue');
// prettier-ignore
const Contacts = () => import('@/entities/contacts/contacts.vue');
// prettier-ignore
const ContactsUpdate = () => import('@/entities/contacts/contacts-update.vue');
// prettier-ignore
const ContactsDetails = () => import('@/entities/contacts/contacts-details.vue');
// prettier-ignore
const AccessRecord = () => import('@/entities/access-record/access-record.vue');
// prettier-ignore
const AccessRecordUpdate = () => import('@/entities/access-record/access-record-update.vue');
// prettier-ignore
const AccessRecordDetails = () => import('@/entities/access-record/access-record-details.vue');
// prettier-ignore
const OperationLog = () => import('@/entities/operation-log/operation-log.vue');
// prettier-ignore
const OperationLogUpdate = () => import('@/entities/operation-log/operation-log-update.vue');
// prettier-ignore
const OperationLogDetails = () => import('@/entities/operation-log/operation-log-details.vue');
// prettier-ignore
const SystemSetting = () => import('@/entities/system-setting/system-setting.vue');
// prettier-ignore
const SystemSettingUpdate = () => import('@/entities/system-setting/system-setting-update.vue');
// prettier-ignore
const SystemSettingDetails = () => import('@/entities/system-setting/system-setting-details.vue');
// prettier-ignore
const Pact = () => import('@/entities/pact/pact.vue');
// prettier-ignore
const PactUpdate = () => import('@/entities/pact/pact-update.vue');
// prettier-ignore
const PactDetails = () => import('@/entities/pact/pact-details.vue');
// prettier-ignore
const PactType = () => import('@/entities/pact-type/pact-type.vue');
// prettier-ignore
const PactTypeUpdate = () => import('@/entities/pact-type/pact-type-update.vue');
// prettier-ignore
const PactTypeDetails = () => import('@/entities/pact-type/pact-type-details.vue');
// prettier-ignore
const Invoice = () => import('@/entities/invoice/invoice.vue');
// prettier-ignore
const InvoiceUpdate = () => import('@/entities/invoice/invoice-update.vue');
// prettier-ignore
const InvoiceDetails = () => import('@/entities/invoice/invoice-details.vue');
// prettier-ignore
const InvoiceType = () => import('@/entities/invoice-type/invoice-type.vue');
// prettier-ignore
const InvoiceTypeUpdate = () => import('@/entities/invoice-type/invoice-type-update.vue');
// prettier-ignore
const InvoiceTypeDetails = () => import('@/entities/invoice-type/invoice-type-details.vue');
// prettier-ignore
const Collect = () => import('@/entities/collect/collect.vue');
// prettier-ignore
const CollectUpdate = () => import('@/entities/collect/collect-update.vue');
// prettier-ignore
const CollectDetails = () => import('@/entities/collect/collect-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

// 自定义添加
const PriCustomer = () => import('@/entities/pri-customer/pri-customer.vue');
// prettier-ignore
const PriCustomerUpdate = () => import('@/entities/pri-customer/pri-customer-update.vue');
// prettier-ignore
const PriCustomerDetails = () => import('@/entities/pri-customer/pri-customer-details.vue');
// 自定义添加结束
let p = sessionStorage.getItem('baseUrl') ? sessionStorage.getItem('baseUrl') : '/';

export default {
  path: p,
  component: Entities,
  children: [
    {
      path: 'company',
      name: 'Company',
      component: Company,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'company/new',
      name: 'CompanyCreate',
      component: CompanyUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'company/:companyId/edit',
      name: 'CompanyEdit',
      component: CompanyUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'company/:companyId/view',
      name: 'CompanyView',
      component: CompanyDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'customer',
      name: 'Customer',
      component: Customer,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'customer/new',
      name: 'CustomerCreate',
      component: CustomerUpdate,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'customer/:customerId/edit',
      name: 'CustomerEdit',
      component: CustomerUpdate,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'customer/:customerId/view',
      name: 'CustomerView',
      component: CustomerDetails,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'contacts',
      name: 'Contacts',
      component: Contacts,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'contacts/new',
      name: 'ContactsCreate',
      component: ContactsUpdate,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'contacts/:contactsId/edit',
      name: 'ContactsEdit',
      component: ContactsUpdate,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'contacts/:contactsId/view',
      name: 'ContactsView',
      component: ContactsDetails,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'access-record',
      name: 'AccessRecord',
      component: AccessRecord,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'access-record/new',
      name: 'AccessRecordCreate',
      component: AccessRecordUpdate,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'access-record/:accessRecordId/edit',
      name: 'AccessRecordEdit',
      component: AccessRecordUpdate,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'access-record/:accessRecordId/view',
      name: 'AccessRecordView',
      component: AccessRecordDetails,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'operation-log',
      name: 'OperationLog',
      component: OperationLog,
      meta: { authorities: [Authority.COMPANY_ADMIN] },
    },
    {
      path: 'operation-log/new',
      name: 'OperationLogCreate',
      component: OperationLogUpdate,
      meta: { authorities: [Authority.COMPANY_ADMIN] },
    },
    {
      path: 'operation-log/:operationLogId/edit',
      name: 'OperationLogEdit',
      component: OperationLogUpdate,
      meta: { authorities: [Authority.COMPANY_ADMIN] },
    },
    {
      path: 'operation-log/:operationLogId/view',
      name: 'OperationLogView',
      component: OperationLogDetails,
      meta: { authorities: [Authority.COMPANY_ADMIN] },
    },
    {
      path: 'system-setting',
      name: 'SystemSetting',
      component: SystemSetting,
      meta: { authorities: [Authority.COMPANY_ADMIN] },
    },
    {
      path: 'system-setting/new',
      name: 'SystemSettingCreate',
      component: SystemSettingUpdate,
      meta: { authorities: [Authority.COMPANY_ADMIN] },
    },
    {
      path: 'system-setting/:systemSettingId/edit',
      name: 'SystemSettingEdit',
      component: SystemSettingUpdate,
      meta: { authorities: [Authority.COMPANY_ADMIN] },
    },
    {
      path: 'system-setting/:systemSettingId/view',
      name: 'SystemSettingView',
      component: SystemSettingDetails,
      meta: { authorities: [Authority.COMPANY_ADMIN] },
    },
    {
      path: 'pact',
      name: 'Pact',
      component: Pact,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'pact/new',
      name: 'PactCreate',
      component: PactUpdate,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'pact/:pactId/edit',
      name: 'PactEdit',
      component: PactUpdate,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'pact/:pactId/view',
      name: 'PactView',
      component: PactDetails,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    // {
    //   path: 'pact-type',
    //   name: 'PactType',
    //   component: PactType,
    //   meta: { authorities: [Authority.USER] },
    // },
    // {
    //   path: 'pact-type/new',
    //   name: 'PactTypeCreate',
    //   component: PactTypeUpdate,
    //   meta: { authorities: [Authority.USER] },
    // },
    // {
    //   path: 'pact-type/:pactTypeId/edit',
    //   name: 'PactTypeEdit',
    //   component: PactTypeUpdate,
    //   meta: { authorities: [Authority.USER] },
    // },
    // {
    //   path: 'pact-type/:pactTypeId/view',
    //   name: 'PactTypeView',
    //   component: PactTypeDetails,
    //   meta: { authorities: [Authority.USER] },
    // },
    {
      path: 'invoice',
      name: 'Invoice',
      component: Invoice,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'invoice/new',
      name: 'InvoiceCreate',
      component: InvoiceUpdate,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'invoice/:invoiceId/edit',
      name: 'InvoiceEdit',
      component: InvoiceUpdate,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'invoice/:invoiceId/view',
      name: 'InvoiceView',
      component: InvoiceDetails,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'invoice-type',
      name: 'InvoiceType',
      component: InvoiceType,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'invoice-type/new',
      name: 'InvoiceTypeCreate',
      component: InvoiceTypeUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'invoice-type/:invoiceTypeId/edit',
      name: 'InvoiceTypeEdit',
      component: InvoiceTypeUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'invoice-type/:invoiceTypeId/view',
      name: 'InvoiceTypeView',
      component: InvoiceTypeDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'collect',
      name: 'Collect',
      component: Collect,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'collect/new',
      name: 'CollectCreate',
      component: CollectUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'collect/:collectId/edit',
      name: 'CollectEdit',
      component: CollectUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'collect/:collectId/view',
      name: 'CollectView',
      component: CollectDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
    //  自定义添加
    {
      path: 'pri-customer',
      name: 'PriCustomer',
      component: PriCustomer,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'pri-customer/new',
      name: 'PriCustomerCreate',
      component: PriCustomerUpdate,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'pri-customer/:customerId/edit',
      name: 'PriCustomerEdit',
      component: PriCustomerUpdate,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    {
      path: 'pri-customer/:customerId/view',
      name: 'PriCustomerView',
      component: PriCustomerDetails,
      meta: { authorities: [Authority.USER, Authority.COMPANY_ADMIN] },
    },
    // 自定义添加结束
  ],
};
