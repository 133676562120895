export default function buildPaginationQueryOpts(paginationQuery) {
  if (paginationQuery) {
    let sorts = '';
    for (const idx of Object.keys(paginationQuery.sort)) {
      if (sorts.length > 0) {
        sorts += '&';
      }
      sorts += 'sort=' + paginationQuery.sort[idx];
    }
    return `${sorts}&page=${paginationQuery.page}&size=${paginationQuery.size}`;
  }
  return '';
}

export function buildQueryOpts(paginationQuery) {
  console.log(paginationQuery, '参数');
  if (paginationQuery) {
    let sorts = '';
    for (const idx of Object.keys(paginationQuery.sort)) {
      if (sorts.length > 0) {
        sorts += '&';
      }
      sorts += 'sort=' + paginationQuery.sort[idx];
    }
    return `action=${paginationQuery.action}&${sorts}&page=${paginationQuery.page}&size=${paginationQuery.size}${
      paginationQuery._uid ? `&_uid=${paginationQuery._uid}` : ''
    }${paginationQuery.actTime ? `&actTime=${paginationQuery.actTime}` : ''}${
      paginationQuery.endTime ? `&endTime=${paginationQuery.endTime}` : ''
    }${paginationQuery.name ? `&selName=${paginationQuery.name}` : ''}`;
  }
  return '';
}
